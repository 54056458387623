* {
    margin: 0;
    padding: 0;
    outline: 0;
}

body, html {
    width: 100%;
    height: 100%;
    font-family: 'Sen', sans-serif;
}

button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-transform: uppercase;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
    